<template>
    <div style="position:relative">
        <v-progress-linear indeterminate v-if="run_q"></v-progress-linear>
        <organization-chart :pan="true" :datasource="items">
            <template slot-scope="{ nodeData }">
                <div>
                    <div v-if="nodeData.AgentName == 'Producers'">
                        <div v-for="(p, key) in nodeData.producers">
                            <div class="q-subtitle">
                                <router-link :to="'/agents/' + p.AgentCode">
                                    {{ p.AgentDisplayName }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="q-subtitle">{{nodeData.LeadershipLevel}}</div>
                        <v-icon small>
                            {{agentIcon(nodeData)}}
                        </v-icon>
                        <div>
                            <router-link :to="'/agents/' + nodeData.AgentCode">
                                {{ nodeData.AgentDisplayName || nodeData.AgentName }}
                            </router-link>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <span>
                                        <v-icon v-on="on" v-if="nodeData.HasDFLSMEInDownline" color="#6ecf93">icon-q-dfl-icon-circle</v-icon>
                                    </span>
                                </template>
                                <span>This agent has a DFL SME in their downline.</span>
                            </v-tooltip>
                        </div>
                        <v-progress-circular size="20" indeterminate v-if="nodeData.loading"></v-progress-circular>
                        <v-btn v-if="nodeData.children.length == 0 && !nodeData.loading" icon small @click="fetchAgents(nodeData)">
                            <v-icon small>fas fa-caret-down</v-icon>
                        </v-btn>
                        <v-btn v-else-if="!nodeData.loading" icon small @click="closeNode(nodeData)">
                            <v-icon small>fas fa-caret-up</v-icon>

                        </v-btn>
                    </div>
                </div>
            </template>
        </organization-chart>
        <div v-if="!items.loading && hasRole('SuperAdmin')" class="top-left">
            <v-btn @click="expandAllChildren">Load All Branches</v-btn>
        </div>
    </div>
    <!--<highcharts :options="chartOptions"></highcharts>//-->
    <!--<combo-chart :height="null" :width="null" :chart-data="chartdata" :options="options"></combo-chart>//-->
</template>
<script>
import OrganizationChart from 'vue-organization-chart'
import 'vue-organization-chart/dist/orgchart.css'
import QuilityAPI from '@/store/API/QuilityAPI.js'
export default {
    name: "QAgencyStructureChart",
    props: ['agent'], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        var g = this;
        //var d2 = mockdata.dailyUniqueWriters();
        //var d3 = mockdata.dailyPlaced();
        if (this.agent.AgentCode == 'SFG00001') {
            var name = "Symmetry"
        } else {
            name = this.agent.AgentName
        }
        return {
            items: {
                AgentName: name,
                id: this.agent.AgentCode,
                AgentCode: this.agent.AgentCode,
                LeadershipLevel: this.agent.LeadershipLevel,
                downline: [],
                type: 'shop',
                loading: false,
                children: [],
                HasDFLSMEInDownline: false,
            },
            qu: [],
            run_q: false
        }
    },
    mounted: function() {
        if (typeof this.agent != 'undefined') {
            this.fetchAgents(this.items);
        }
    },
    computed: {

    },
    methods: {
        fetchAgents: function(agent) {
            var g = this
            if (typeof agent == 'undefined') {
                return;
            }
            if (typeof agent.cached_children != 'undefined') {
                this.$set(agent, 'children', agent.cached_children);
                return;
            }
            agent.loading = true
            QuilityAPI.getAgentDownline(agent.AgentCode, ['LeadershipLevel'], 'tree-structure', this.hasRole(['AgencyOwner', 'SalesRep']), true).then(function(resp) {
                g.$set(agent, 'children', resp.data.children);
                // g.$set(g, 'items.HasDFLSMEInDownline', resp.data.HasDFLSMEInDownline)
                if(!g.items.HasDFLSMEInDownline)
                {
                    g.items.HasDFLSMEInDownline = resp.data.HasDFLSMEInDownline
                }
                agent.loading = false
                if (g.loading) {
                    for (var i = 0; i < resp.data.children.length; i++) {
                        if (resp.data.children[i].AgentName != 'Producers') {
                            g.addAgentToQ(resp.data.children[i]);
                            if (!g.run_q) {
                                g.run_q = true
                                g.runQ();
                            }
                        }
                    }

                }
            });
        },
        closeNode: function(agent) {
            this.$set(agent, 'cached_children', agent.children);
            this.$set(agent, 'children', []);
        },
        runQ() {
            if (!this.run_q) {
                return
            }
            if (this.qu.length > 0) {
                var agent = this.qu.pop();
                var g = this
                setTimeout(function() {
                    g.fetchAgents(agent);
                    g.runQ();
                }, 1000)
            } else {
                this.run_q = false;
            }
        },
        addAgentToQ($agent) {
            this.qu.push($agent)
        },
        sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        expandAllChildren: function() {
            var g = this
            this.loading = true
            this.run_q = true
            for (var i = 0; i < this.items.children.length; i++) {
                if (this.items.children[i].AgentName != 'Producers') {
                    this.addAgentToQ(this.items.children[i]);
                }
            }
            this.runQ();
        },
        agentIcon: function(agent) {
            if (agent.AgentCode == 'SFG00001') {
                return 'fas fa-city'
            }
            if (agent.AgentName == 'Producers') {
                return
            }
            var level = agent.LeadershipLevel
            return this.getAgentIcon(level);
        }
    },
    watch: {
        'agent': function(newV) {
            items = {
                AgentName: newV.AgentName,
                id: newV.AgentCode,
                AgentCode: newV.AgentCode,
                LeadershipLevel: newV.LeadershipLevel,
                downline: [],
                type: 'shop',
                loading: false,
                children: []
            }
            this.fetchAgents(items);
        }
    },
    components: {
        OrganizationChart
    },
    beforeRouteLeave(to, from, next) {

    }
}

</script>
<style scoped>
.top-left {
    position: absolute;
    top: 15px;
    right: 45px;
}

</style>

<template>
  <div class="q-agent-directory-table-badges">
    <v-tooltip right v-for="badge in badges" :key="badge.icon">
      <template v-slot:activator="{ on }">
        <i v-if="badge.icon" :class="badge.icon" v-on="on" color="black" style="max-height: 40px; width: 40px; font-size: 1.25rem;" />
        <img v-else-if="badge.img" :src="badge.img" style="max-height: 40px; width: 40px;" v-on="on" />
      </template>
      <span>{{ badge.tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    agent: { type: Object, required: true }
  },

  computed: {
    dflBadge() {
      return (this.hasAllowedStatus && this.agent.DFLCertifiedInd) ? {
        icon: 'icon-q-dfl-icon',
        tooltip: 'DFL Certified Badge'
      } : undefined;
    },

    annuityBadge() {
      return (this.hasAllowedStatus && this.agent.AnnuityCertifiedInd) ? {
        icon: 'icon-q-anu-icon',
        tooltip: 'Annuity Certified Badge'
      } : undefined;
    },

    iulBadge() {
      return (this.hasAllowedStatus && this.agent.IULCertifiedInd) ? {
        icon: 'icon-q-iul-icon',
        tooltip: 'IUL Certified Badge'
      } : undefined;
    },

    summitBadge() {
      return this.agent.CurrentSummitBadge ? {
        img: this.agent.CurrentSummitBadge.micro,
        tooltip: this.agent.CurrentSummitBadge.name || 'Summit Badge'
      } : undefined;
    },

    slingshotBadge() {
      return this.agent.SlingshotBadges ? {
        img: this.slingshotBadgeUrl(this.agent.SlingshotBadges),
        tooltip: 'Slingshot Badge'
      } : undefined;
    },

    navigatorAccessBadge() {
      return this.agent.NavigatorAccess ? {
        img: 'https://hq-image-cdn.azureedge.net/servers/production/cms_images/navigator_badge_Small_9ONPPA.svg',
        tooltip: 'Navigator Course Certification Badge'
      } : undefined
    },

	ibcBadge() {
	  return (this.hasAllowedStatus && this.agent.IBCCertifiedInd) ? {
		img: 'https://hq-image-cdn.azureedge.net/servers/production/cms_images/23-11-08_IBC-HQ-Badge_eg9557.svg',
		tooltip: 'IBC Certified Badge'
	  } : undefined;
	},

    badges() {
      return [this.dflBadge, this.annuityBadge, this.iulBadge, this.ibcBadge, this.summitBadge, this.slingshotBadge, this.navigatorAccessBadge].filter(Boolean);
    },

	hasAllowedStatus () {
		const disallowedStatuses = ['Lapsed', 'Stalled', 'Terminated']
		return !disallowedStatuses.includes(this.agent.Status)
	},
  },
})
</script>

<style lang="scss" scoped>
  .q-agent-directory-table-badges {
    display: inline-flex;
    align-items: center;
	gap: 0.5rem;
  }
</style>
<style scoped>
    .summit-badge {
        width: 1.5rem;
        margin-left: 0.3rem;
    }
</style>

<template>
    <div>
        <v-card>
            <QDataTableHeader class="q_leads_1" :id="entity + '_datatable'">
                <template #left>
                    <p class="q-display-3 pb-0 pt-0 mt-0 mb-0 white--text">{{ title }}
                        <v-btn v-if="moreLink" text fab :to="{name:moreLink}">
                            <v-icon color="q_leads_4">fas fa-arrow-circle-right</v-icon>
                        </v-btn>
                    </p>
                    <p class="pt-0 mt-0">
                        <v-btn text small color="q_leads_4" @click="toggleFilters">
                            <v-icon small left>fas fa-filter</v-icon> Filter Agents
                        </v-btn>
                    </p>
                </template>
                <template #right>
                    <v-text-field dense dark v-model="search" :disabled="isDemoMode" append-icon="icon-q-search" label="Search" single-line hide-details v-on:keyup="maybeSearch" @click:append="refreshData"></v-text-field>
                </template>
            </QDataTableHeader>

            <v-row class="d-flex justify-start" v-if="toggleFilters">
                <v-col cols="12">
                    <QFilters
                        v-model="filters"
                        :available-filters="the_available_filters"
                        :keys="filter_keys"
                        :checkboxesFilters="checkboxesFilters"
                        :hide-button="true"
                        :show-reset="true"
                        dark
                        ref="filters"
                    />
                </v-col>
            </v-row>

            <QExpandableDataTable v-on:current-items="updateCurrentItems" :visibleHeaders="visibleHeaders" :mobile-breakpoint="2" :items-per-page.sync="computed_rows" :search="search" v-model="selected" :headers="the_headers" :items="all_data" item-key="$id" class="elevation-0" :footer-props="{itemsPerPageOptions:[10,25,50,100]}" :loading="loading" :options.sync="options" :server-items-length="total_items" :expanded.sync="expanded" color="q_leads_1">
                <template v-slot:item.AgentName="{ item }">
                    <div class="q-agent-directory-table__agent-name-filed">
                        <span class="nowrap">{{item.AgentName}}</span>
                        <v-chip
							v-if="item.Division !== 'Field'"
							title="Division"
							color="q_leads_1"
							small
							class="white--text"
						>{{ item.Division }}</v-chip>
                        <v-chip
							class="ml-7"
							:color="item.Status === 'Active' ? 'primary' : 'warning'"
							text-color="white"
						>{{ item.Status }}</v-chip>
                    </div>
                </template>
                <template v-slot:item.TableBadge="{ item }">
                    <div>
                        <QAgentDirectoryTableBadge :agent="item" />
                    </div>
                </template>
                <template v-slot:after-expand="{ item }">
                    <span class="nowrap ml-4">
                        <v-btn color="q_new_b_1 white--text  body-2" :to="{ name: 'AgentDetail', params: { agentcode: item.AgentCode} }">View</v-btn>
                    </span>
                </template>
                <template v-slot:item.AgentEmail="{ item }">
                    <span class="nowrap ml-4" style="z-index:2000;">
                    <a :href="'mailto:' + item.AgentEmail">{{ item.AgentEmail }}</a>
                    </span> 
                </template>
                <template v-slot:item.AgentPhone="{ item }">
                    <a :href="'tel:' + item.AgentPhone">{{ item.AgentPhone }}</a>
                </template>
                <template v-slot:item.StreetAddress="{ item }">
                    <span class="nowrap">
                        {{ item.AgentName }}<br>
                        {{ item.StreetAddress }}<br>
                        {{ item.City }}, {{ item.State }} {{ item.Zip }}<br>
                    </span>
                </template>
                <template v-slot:body.append>
                    <tr>
                        <td colspan="12">
                            <v-btn @click="downloadData">Export</v-btn>
                            <v-progress-linear v-if="loading" class="mt-2" color="primary" indeterminate></v-progress-linear>
                        </td>
                    </tr>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" v-if="$vuetify.breakpoint.lgAndUp">
                        <v-row wrap>
                            <v-col cols="6" class="pr-6">
                                <v-row class="justify-space-between pl-4">
                                        <div> <span class="overline">Email:</span>
                                
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="ml-auto" v-bind="attrs"
                                            v-on="on" text @click.stop="null" v-clipboard:copy="item.AgentEmail"
                                            v-clipboard:success="handleCopyStatus">{{ item.AgentEmail }}
                                        </v-btn>
                                    </template>
                                    <span>Click to copy</span>
                                </v-tooltip>
                                </div>
                                </v-row>
                                <v-divider></v-divider>
                                <v-row class="justify-space-between pl-4">
                                        <div> <span class="overline">Phone:</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn class="ml-auto" v-bind="attrs"
                                                v-on="on" text @click.stop="null" v-clipboard:copy="item.AgentPhone"
                                                v-clipboard:success="handleCopyStatus">{{ item.AgentPhone }}
                                            </v-btn>
                                        </template>
                                        <span>Click to copy</span>
                                    </v-tooltip>
                                    </div>
                                    </v-row>
                            </v-col>
                            <v-col cols="6">
                                <v-row class="justify-space-between pl-4">
                                    <div> <span class="overline">Agent Code:</span>
                                    <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn class="ml-auto" v-bind="attrs"
                                                    v-on="on" text @click.stop="null" v-clipboard:copy="item.AgentCode"
                                                    v-clipboard:success="handleCopyStatus">{{ item.AgentCode }}
                                                </v-btn>
                                            </template>
                                            <span>Click to copy</span>
                                        </v-tooltip>
                                    </div>
                                    </v-row>
                                    <v-divider></v-divider>
                                    <v-row class="justify-space-between pl-4">
                                    <div><span class="overline">OptID:</span>
                                    <v-tooltip top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn class="ml-auto" v-bind="attrs"
                                                    v-on="on" text @click.stop="null" v-clipboard:copy="item.OptID"
                                                    v-clipboard:success="handleCopyStatus">{{ item.OptID }}
                                                </v-btn>
                                            </template>
                                            <span>Click to copy</span>
                                        </v-tooltip>
                                        </div>
                                    </v-row>
                            </v-col>
                            <v-col cols="6" sm="6">
                                <div class="overline">Address:</div>
                                {{ item.AgentName }}<br>
                                {{ item.StreetAddress }}<br>
                                {{ item.City }}, {{ item.State }} {{ item.Zip }}<br>
                            </v-col>
                            <v-col cols="6" sm="6">
                                
                            </v-col>
                        </v-row>
                    </td>
                </template>
				<template v-slot:item.ContractStartDt="{ item }">
					{{ formatDate(item.ContractStartDt) }}
				</template>
				<template v-slot:item.LastActivityDate="{ item }">
					{{ formatDate(item.LastActivityDate) }}
				</template>
            </QExpandableDataTable>
        </v-card>

        <v-dialog v-model="dialog" width="400">
            <v-card class="pa-6 text-center" min-height="">
                <div class="pa-4 display-3 font-weight-bold">Oops...</div>
                <p>I don't see a Power Dialer account for you.</p>
                <v-btn color="primary">Purchase Power Dialer</v-btn>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import QDataTableMixin from "./QDataTableMixin"
import Agent from '@/store/Models/Agent'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QDataTableHeader from './base/QDataTableHeader.vue'
import { debounce } from 'vue-debounce'
import QExpandableDataTable from '../datatables/QExpandableDataTable.vue'
import QAgentDirectoryTableBadge from "./QAgentDirectoryTableBadge.vue"

export default {
    name: "QAgentsDirectoryTable",
    mixins: [QDataTableMixin],
    data: function() { // data internal to component (not available outside)
        return {
            expanded: [],
            entity: 'agent',
            sync_dialog: false,
            //use this to "preset" a filter
            filters: {
                'Status': ['Active'],
                'ContractLevel': [],
                'State': [],
                'LeadershipLevel': [],
                'Division': ['Field'],
            },
            firstPull: true, // this keeps the auto scrolling on data reloads from happening on the first load.
            options: { itemsPerPage: 25, sortBy: ['AgentName'], sortDesc: [false] }
        }
    },
    computed: {
        checkboxesFilters: function() {
            return ['Licensed','PCSParticipant','AvailableInd','SeasonedNewAgentInd','IsOwnerCircleMember','HasSherpaInd','PCSAgentInd', 'PCSOnHoldInd', 'IBCCertifiedInd', 'IULCertifiedInd', 'AnnuityCertifiedInd', 'DFLCertifiedInd', 'IsAdvisoryBoardMember', "LegacyAdvisoryBoardMember", 'PCSProbationInd', 'Six_Apps', 'Fifteen_Apps', 'Twenty_Five_Apps', 'Full_Time', 'Part_Time', 'Builder', 'Producer', 'AnnuitySME', 'DFLSME']
        },
        the_available_filters: function() {
            if(!this.available_filters) {
                return this.available_filters
            }

            //stripping the -All- option from the filters since these will all now be
            const filters = {}
            this.filter_keys.forEach((key) => {
                //if (filters[key] == null) return;
                const isCheckbox = this.checkboxesFilters.includes(key)
                const currentFilter = this.available_filters[key]
                filters[key] = isCheckbox
                    ? currentFilter
                    : currentFilter.filter(option => option !== '-All-')
            })
            return filters
        },
        filter_keys: function() {
            if (this.hasRole(['SalesRep', 'AgencyOwner'])) {
                const keys = ['Status', 'ContractLevel', 'State', 'LeadershipLevel', 'SeasonedNewAgentInd', 'AnnuitySME', 'DFLSME', 'DFLCertifiedInd', 'IBCCertifiedInd', 'IULCertifiedInd']

                if(this.hasRole(['AgencyOwner'])) {
                    return [...keys, 'Six_Apps', 'Fifteen_Apps', 'Twenty_Five_Apps', 'Full_Time', 'Part_Time', 'Builder', 'Producer']
                }
                return keys
            }
            return Agent.state().filter_keys;
        },
        all_data: function() {
			return Agent.all() 
        },
        expandTableHeaders() {
            return [
                {
                    text: 'Email',
                    value: 'AgentEmail',
                    align: 'start',
                    class: 'nowrap',
                },
                {
                    text: 'Phone',
                    value: 'AgentPhone',
                    align: 'start',
                    class: 'nowrap',
                },
                {
                    text: 'Address',
                    value: 'StreetAddress',
                    align: 'start',
                    class: 'nowrap',
                },
                {
                    text: 'Agent Code',
                    value: 'AgentCode',
                    align: 'start',
                    class: 'nowrap',
                },
                {
                    text: 'OptID',
                    value: 'OptID',
                    align: 'start',
                    class: 'nowrap',
                },
            ]
        },
        the_headers: function() {
            const headers = [{
                    text: 'Name',
                    value: 'AgentName',
                    align: 'start',
                    sortable: true,
                    filterable: true,
                    class: 'nowrap',
                },

                {
                    text: 'Badges',
                    value: 'TableBadge',
                    sortable: false,
                    filterable: true,
                    class: 'nowrap',
                },
                {
                    text: 'Contract',
                    value: 'ContractLevel',
                    sortable: true,
                    filterable: true,
                    class: 'nowrap',
                },
                {
                    text: 'Upline Agent',
                    value: 'UplineAgentName',
                    sortable: false,
                    filterable: true,
                    class: 'nowrap',
                },
                {
                    text: 'Baseshop',
                    value: 'BaseshopOwnerAgentName',
                    sortable: false,
                    filterable: true,
                    class: 'nowrap',
                },
                {
                    text: 'State',
                    value: 'State',
                    sortable: true,
                    filterable: true,
                    class: 'nowrap',
                },
                {
                    text: 'Leadership Level',
                    value: 'LeadershipLevel',
                    sortable: true,
                    filterable: true,
                    class: 'nowrap',
                },
                {
                    text: 'Start date',
                    value: 'ContractStartDt',
                    sortable: true,
                    filterable: true,
                    class: 'nowrap',
                },
                {
                    text: '(Opt) Last Active',
                    value: 'LastActivityDate',
                    sortable: true,
                    filterable: true,
                    class: 'nowrap',
                },
            ];

            if (this.$vuetify.breakpoint.mdAndDown) {
                return [
                    ...this.expandTableHeaders,
                    ...headers,
                ]
            }

            return headers
        },

        visibleHeaders() {
            if (this.$vuetify.breakpoint.xs) {
                return [
                    'AgentName',
                ]
            }

            if (this.$vuetify.breakpoint.smAndDown) {
                return [
                    'AgentName',
                    'State'
                ]
            }

            if (this.$vuetify.breakpoint.mdAndDown) {
                return this.the_headers.slice(this.expandTableHeaders.length, this.expandTableHeaders.length + 4)
            }

            return []
        },

        canViewAllAgents() {
            return this.hasRole(['SuperAdmin', 'Exec', 'Staff']);
        }
    },
    methods: {
         handleCopyStatus(status) {
            this.showInfo("Copied to clipboard!")
        },
        handleCopyError(status) {
            this.showError("Opps, something went wrong. Select the deisred text and use CTRL+C to copy.")
        },

        loadData: debounce(function(opt) {
            var g = this
            QuilityAPI.getAgents(
                this.reformatFilters(),
                { attr: 'LeadershipLevel,BaseshopOwnerAgentName,UplineAgentName,SFGDirectAgentName,CurrentSummitBadge,NavigatorAccess', ...opt },
                this.canViewAllAgents
            ).catch(function(err) {
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
                //g.showError(err.status)
            });
            this.firstPull = false;
        }, 200),

        downloadData: debounce(function() {
            var opt = {
                ...this.options,
                search: this.search,
            }
            if (this.user.Agent.AgentCode != 'SFG0000001') {
                var endpoint = '/api/my/agents';
            } else {
                endpoint = '/api/private/agents';
            }
            var params = {
                ...opt,
                ...this.reformatFilters(),
                'itemsPerPage': "csv",
                'attr': 'LeadershipLevel,BaseshopOwnerAgentName,UplineAgentName,SFGDirectAgentName'
            }
            this.dispatchJob({
                'method': "GET",
                'path': endpoint,
                'params': params,
                'JobName': "Agent Export"
            })
            /*
            this.data_loading = true
            var g = this;
            opt.itemsPerPage = 'all';
            QuilityAPI.getAgents(this.filters, { attr: 'LeadershipLevel,BaseshopOwnerAgentName,UplineAgentName,SFGDirectAgentName', ...opt }, (this.user.Agent.IsAgencyOwner || this.user.Agent.HasManagerAccess))
                .then(function(json) {
                    var fields = ['AgentCode', 'OptID', 'AgentName', 'OriginalAgentName', 'FirstName', 'LastName', 'MiddleName', 'NickName', 'PreferredName', 'AgentEmail', 'AgentPhone', 'AddressLine1', 'AddressLine2', 'City', 'County', 'State', 'Zip', 'NPN', 'LeadershipLevel', 'ContractLevel', 'LeadershipLevelOverride', 'ProducerLevel', 'UplineAgentCode', 'BaseshopAgentCode', 'SFGDirectAgentCode', 'BaseshopOwnerAgentName', 'UplineAgentName', 'SFGDirectAgentName', 'ContractStartDt', 'IsAgencyOwner', 'LastActivityDate', 'PCSParticipant', 'PCSStartDate'];
                    g.startDownload(json.data, fields)
                    g.data_loading = false
                })
                .catch(function(err) {
                    g.data_loading = false
                    if (typeof err == 'undefined') {
                        return
                    }
                    if (err.status == 431) {
                        g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                        return
                    }
                    if (typeof err.status == 'string' && err.status != '') {
                        g.showError(err.status)
                    }
                });
            */
        }, 200),

        showSyncDialog: function() {
            this.sync_dialog = true;
            var g = this;
            setTimeout(function() { g.sync_dialog = false }, 4500);
        },

        truncateEmail: function(email) {
            let threshold = 30

            if (email.length <= threshold) {
                return email
            }

            // subtract 3 from threshold to account for the ellipsis
            return `${email.slice(0,(threshold - 3))}...`
        },
        // This will simply flatten any array of filter options info a single comma-separated string of values
        // which is what the Filters.php applyFilters function looks for on a multiselect
        reformatFilters() {
            let new_filters = {}
            this.filter_keys.map(key => {
                if(typeof this.filters[key] == "object") {
                    new_filters[key] = this.filters[key].join(",")
                } else {
                    new_filters[key] = this.filters[key]
                }
            })
            return new_filters
        },
    },
    components: {
        QDataTableHeader,
        QExpandableDataTable,
        QAgentDirectoryTableBadge,
    }
}

</script>

<style scoped>
.nowrap {
    white-space: nowrap;
}
</style>


<style lang="scss">
.q-agent-directory-table__agent-name-filed {
    display: flex;
    align-items: center;

    & > * + * {
        margin-left: 8px;
    }

    .v-chip {
        min-width: fit-content;
    }
}

</style>

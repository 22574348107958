import { render, staticRenderFns } from "./QSimpleTable.vue?vue&type=template&id=3108cf32&"
import script from "./QSimpleTable.vue?vue&type=script&lang=js&"
export * from "./QSimpleTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<!-- This needs refactoring, it's confusing because we no longer need a "minimal" version anymore
Eventually this should be replaced with the score card design  -->
<template>
    <div>
        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
        <v-row class="pa-0" v-if="!loading">
            <v-col cols="12" md="5">
                <v-row wrap>
                    <v-col cols="12">
                        <div class="q-display-2">
                            <span>New Agent KPIs</span>
                            <q-tooltip-details>
                                <p><strong>Average Applications Per Agent:</strong> Derived by counting all applications submitted and divide by the number of agents. Counts are derived based on the the agents (active and terminated agents) in the agent's Base Shop. 6 month rolling average.</p>
                                <p><strong>Average Days to Submit First App:</strong> The number of days the new agent takes to place their first app. Counts are derived based on the all the agents (active and terminated agents) in the agent's Base Shop. 6 month rolling average.</p>
                                <p><strong>New Agent Conversion Rate:</strong>A 90-day rolling average of new agents that have been onboarding compared to new agents to have also written business in those 90 days.</p>
                            </q-tooltip-details>
                        </div>
                        <v-card class="pl-3 pr-3 pb-3 mb-3">
                            <q-comparison-chart v-if="stats.avgApplicationsPerAgentComparison" label="<strong>Average Apps Per New Agent</strong>" format="number" :current="avg_apps_per_agent" :previous="avg_apps_per_agent_comparison" :goal="6" primary-color="#337974" secondary-color="#d2e8ec" previous-category="Goal" current-category="6 months"></q-comparison-chart>
                            <q-simple-table :items="avg_apps_per_agent_data"></q-simple-table>
                        </v-card>
                        <v-card class="pl-3 pr-3 pb-3 mb-3">
                            <q-comparison-chart :smaller-is-good="true" v-if="stats.avgDaysToSubmitFirstApp" label="<strong>Avg Days to Submit App</strong>" format="number" :current="avg_days_to_submit_app" :previous="avg_days_to_submit_app_comparison" :goal="21" primary-color="#6bcfc1" secondary-color="#d2e8ec" previous-category="Goal" current-category="6 months"></q-comparison-chart>
                        </v-card>
                        <v-card class="pl-3 pr-3 pb-3">
                            <q-comparison-chart-close-ratio v-if="stats.newAgents49Days > -1" category="6 Week Rolling" label=" <strong>RTW Conversion Rate</strong>" :current="parseFloat(stats.newAgentsWithBusiness49Days)" :previous="parseFloat(stats.newAgents49Days)" :goal="parseFloat(stats.newAgents49Days) * .25" primary-color="#669b97" secondary-color="#d2e8ec" previous-category="New Agents" current-category="New Writers"></q-comparison-chart-close-ratio>
                            <q-simple-table :items="new_agents_90_days"></q-simple-table>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="7">
                <v-list-item one-line>
                    <v-list-item-content>
                        <v-list-item-title class="q-display-2">Monthly New Agents
                            <q-tooltip-details>
                                <p>Shows how many new agents were onboarded in each month along with those who have written business</p>
                            </q-tooltip-details>
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <q-monthly-new-agent-chart v-if="stats.monthlyNewAgentData" :data="stats.monthlyNewAgentData.newAgentsAll" :business-data="stats.monthlyNewAgentData.newAgentsWithBusiness"></q-monthly-new-agent-chart>
                <q-new-agent-apps-data-table :agent="agent" title="RTW" v-if="!hasRole(['SalesRep'])"></q-new-agent-apps-data-table>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QTooltipDetails from '@/components/utils/QTooltipDetails.vue';
import QComparisonChart from '@/components/charts/QComparisonChart.vue';
import QComparisonChartCloseRatio from '@/components/charts/QComparisonChartCloseRatio.vue';
import QMonthlyNewAgentChart from '@/components/charts/QMonthlyNewAgentChart.vue';
import QSimpleTable from '@/components/datatables/QSimpleTable.vue';
import QNewAgentAppsDataTable from '@/components/datatables/QNewAgentAppsDataTable.vue';
export default {
    props: {
        'agent': {
            type: Object,
            default: function() {
                return {}
            }
        },
    },
    data: function() {
        return {
            stats: {
                "avgApplicationsPerAgent": null,
                "avgApplicationsPerAgentComparison": null,
                "avgDaysToSubmitFirstApp": null,
                "avgDaysToSubmitFirstAppComparison": null,
                "monthlyNewAgentData": null,
                "newAgents49Days": null,
                "newAgentsWithBusiness49Days": null,
                "rolling90NewAgentConversionRate": null,
            },
            loading: false,
        }
    },
    mounted: function() {
        this.loadData()
    },
    computed: {
        'avg_apps_per_agent': function() {
            if (this.stats.avgApplicationsPerAgent == null) {
                return 1;
            }
            return parseFloat(this.formatNumber(this.stats.avgApplicationsPerAgent.AvgAppsPerAgent, '0.0'));
        },
        'avg_apps_per_agent_comparison': function() {
            if (this.stats.avgApplicationsPerAgentComparison == null) {
                return 1;
            }
            return parseFloat(this.formatNumber(this.stats.avgApplicationsPerAgentComparison.AvgAppsPerAgent, '0.0'));
        },
        'avg_days_to_submit_app': function() {
            if (this.stats.avgDaysToSubmitFirstApp == null) {
                return 1;
            }
            return parseFloat(this.formatNumber(this.stats.avgDaysToSubmitFirstApp.DaysToSubmitFirstApp, '0.0'))
        },
        'avg_days_to_submit_app_comparison': function() {
            if (this.stats.avgDaysToSubmitFirstAppComparison == null) {
                return 1;
            }
            return parseFloat(this.formatNumber(this.stats.avgDaysToSubmitFirstAppComparison.DaysToSubmitFirstApp, '0.0'))
        },
        'avg_apps_per_agent_data': function() {
            if (this.stats.avgApplicationsPerAgent == null) {
                return [];
            }
            return [{
                    label: "# New Agents",
                    value: this.stats.avgApplicationsPerAgent.AgentCount
                },
                {
                    label: "# Apps",
                    value: this.stats.avgApplicationsPerAgent.AppCount
                }
            ]
        },
        'new_agents_90_days': function() {
            if (this.stats.avgDaysToSubmitFirstApp == null) {
                return [];
            }
            return [{
                    label: "# New Agents",
                    value: this.stats.newAgents49Days
                },
                {
                    label: "# New Writers",
                    value: this.stats.newAgentsWithBusiness90Days
                }
            ]
        },
    },
    methods: {
        loadData: function() {
            if (typeof this.agent == 'undefined') {
                return;
            }
            var g = this
            this.loading = true;
            QuilityAPI.getNewAgentStats(this.agent.AgentCode, {}, this.role).then(function(json) {
                g.loading = false;
                g.$set(g, 'stats', json.data);
                //g.$set(g, 'agent', json.meta.agent);
            }).catch(function(err) {
                if (typeof err == 'undefined') {
                    return;
                }
                if (err.message == "Aborted") {
                    return;
                }
                g.loading = false;
                if (err.errors == "Page not found") {
                    g.showError("You don't appear to have any stats.");
                    return
                }
                g.showError(err.message);
            })
        },
        loadAgentStats(agentcode) {
            this.$router.push('/my_stats/agents/' + agentcode)
        }
    },
    watch: {
        'agent.AgentCode': function(newV) {
            this.stats = {
                "rollingCloseRatio6weeks": {},
                "rollingCloseRatioPrevious6weeks": {},
                "monthlyProduction": {},
                "monthlyProductionPrevious": {},
                "salesData": [],
            }
            this.$nextTick(this.loadData);
        }
    },
    components: {
        QTooltipDetails,
        QComparisonChart,
        QComparisonChartCloseRatio,
        QMonthlyNewAgentChart,
        QSimpleTable,
        QNewAgentAppsDataTable
    }
}

</script>

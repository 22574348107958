<template>
    <div>
        <v-simple-table dense>
            <template v-slot:default>
                <tbody>
                    <tr v-for="item in items" :key="item.label">
                        <td>{{ item.label }}</td>
                        <td>{{ item.value }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
    </div>
</template>
<script>
export default {
    props: ['items']
}

</script>

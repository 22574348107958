<template>
    <highcharts v-if="data && data.length > 0" :options="chartOptions"></highcharts>
    <!--<combo-chart :height="null" :width="null" :chart-data="chartdata" :options="options"></combo-chart>//-->
</template>
<script>
import { Chart } from 'highcharts-vue'
import colors from './ChartColors';
import dataTransformer from '@/store/dataTransformer'
import theme from './Theme'

export default {
    name: "QMonthlyNewAgentChart",
    props: ['height', 'data', 'businessData'], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        var g = this;
        var monthlyLabels = dataTransformer.getMonthlyLabels("PeriodMonth", "PeriodYear", this.data, 9)
        var new_agents = dataTransformer.monthlyData('NewAgents', "PeriodMonth", "PeriodYear", this.data, monthlyLabels);
        var business_agents = dataTransformer.monthlyData('NewAgents', "PeriodMonth", "PeriodYear", this.businessData, monthlyLabels);
        var conversion_rate = dataTransformer.conversionRate(new_agents, business_agents);
        return {
            chartOptions: {
                chart: {
                    type: 'line',
                    plotShadow: true,
                    plotBackgroundColor: 'rgba(255, 255, 255, .9)',
                    zoomType: 'x',
                    height: this.height ? this.height : "40%"
                },
                title: {
                    text: '',
                },
                xAxis: {
                    //type: 'datetime',
                    categories: monthlyLabels
                },
                yAxis: [{
                    title: {
                        text: 'Agents'
                    },
                    labels: {
                        formatter: function(v) {
                            return this.value;
                        }
                    },
                    softMin: 0
                }, {
                    title: {
                        text: 'Conversion Rate'
                    },
                    labels: {
                        formatter: function(v) {
                            return g.formatPercentage(this.value);
                        }
                    },
                    opposite: true,
                    softMin: 0
                }],
                legend: {
                    enabled: true
                },
                tooltip: {
                    shared: true,
                    //headerFormat: '<b>{series.name}</b><br>',
                    pointFormatter: function(v) {
                        var value = this.y
                        if (this.series.name == "New Agents" || this.series.name == "w/Business") {
                            value = this.y
                        } else {
                            value = g.formatPercentage(this.y)
                        }
                        return "<span class='highcharts-color-" + this.colorIndex + "'>●</span>" + this.series.name + " : <strong>" + value + "</strong><br/> "
                    },
                },
                plotOptions: {
                    column: {
                        pointPadding: .3,
                        borderWidth: 0
                    }
                },
                series: [{
                        type: 'column',
                        name: 'New Agents',
                        data: new_agents,
                        color: "#d2e8ec"
                    }, {
                        type: 'column',
                        name: 'w/Business',
                        data: business_agents,
                        color: "#669b97",
                    },
                    {
                        type: 'line',
                        name: 'Conversion Rate',
                        data: conversion_rate,
                        yAxis: 1,
                        //colorIndex: 4,
                    },
                    /*
                    {
                        type: 'column',
                        name: 'Unique Writers',
                        data: d2,
                        yAxis: 1,
                    },*/

                ],
            },
        }
    },
    mounted: function() {
        //this.renderChart(this.chartData, this.options)
        //Chart.
    },
    computed: {

    },
    methods: {

    },
    watch: {

    },
    components: {
        highcharts: Chart
    }
}

</script>
<style scoped>
</style>
